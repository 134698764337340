<template>
  <form>
    <v-card flat>
      <v-row>
        <v-col cols="4" sm="4" md="2" lg="2" class="pl-5 py-0">
          <v-checkbox v-model="checkbox" label="Sub Account"></v-checkbox>
        </v-col>
        <v-col cols="8" sm="8" md="4" lg="4" v-if="checkbox" class="pb-0">
          <v-autocomplete
            v-model="form.pointerId"
            :items="dropDownChartOfAccount"
            outlined
            flat
            dense
            item-value="accountId"
            item-text="accountName"
            background-color="white"
            :filter="filterObject"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.accountNumber"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
          <v-subheader>Account Number <span class="red--text">*</span></v-subheader>
        </v-col>
        <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
          <v-text-field
            v-model="form.accountNumber"
            required
            outlined
            flat
            dense
            background-color="white"
            :error-messages="accountNumberErrors"
            @input="$v.form.accountNumber.$touch()"
            @blur="$v.form.accountNumber.$touch()"
            ref="accountNumber"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
          <v-subheader>Account Name <span class="red--text">*</span></v-subheader>
        </v-col>
        <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
          <v-text-field
            v-model="form.accountName"
            :error-messages="accountNameErrors"
            required
            outlined
            flat
            dense
            background-color="white"
            @input="$v.form.accountName.$touch()"
            @blur="$v.form.accountName.$touch()"
            ref="accountName"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
          <v-subheader>Nature <span class="red--text">*</span></v-subheader>
        </v-col>
        <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
          <v-select
            v-model="form.nature"
            :items="listNature"
            outlined
            flat
            dense
            background-color="white"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
          <v-subheader>Currency <span class="red--text">*</span></v-subheader>
        </v-col>
        <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
          <v-select
            v-model="form.currencyId"
            :items="dropDownCurrency"
            outlined
            flat
            dense
            background-color="white"
            @change="onChangeCurrency"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
          <v-subheader>Balance</v-subheader>
        </v-col>
        <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
          <v-text-field
            v-model="form.nominal"
            outlined
            flat
            dense
            background-color="white"
            :prefix="symbol"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
          <v-subheader>Balance Date</v-subheader>
        </v-col>
        <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
          <v-menu
            v-model="menuStart"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="form.openingDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="form.openingDate" @input="menuStart = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
          <v-subheader>Vendor</v-subheader>
        </v-col>
        <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
          <v-autocomplete
            flat
            dense
            outlined
            background-color="white"
            v-model="form.vendorId"
            item-text="supplier_name"
            item-value="id"
            :items="vendors"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
          <v-subheader>Customer</v-subheader>
        </v-col>
        <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
          <v-autocomplete
            flat
            dense
            outlined
            background-color="white"
            v-model="form.customerId"
            item-text="name"
            item-value="id"
            :items="customers"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
          <v-subheader>Transaction</v-subheader>
        </v-col>
        <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
          <v-radio-group v-model="form.transaction" row>
            <v-radio label="YES" :value="true"></v-radio>
            <v-radio label="NO" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
          <v-subheader>Type</v-subheader>
        </v-col>
        <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
          <v-text-field
            flat
            dense
            outlined
            background-color="white"
            v-model="form.type"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>
    <v-btn class="mr-4 mt-4" @click="submit" color="primary">
      submit
    </v-btn>
    <v-btn @click="clear" class="mt-4">
      clear
    </v-btn>
  </form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  mixins: [validationMixin],

  validations: {
    form: {
      accountNumber: { required },
      accountName: { required },
    },
  },

  data: () => ({
    defaultForm: {
      pointerId: null,
      accountNumber: "",
      accountName: "",
      nature: null,
      currencyId: 1,
      nominal: 0,
      openingDate: new Date().toISOString().substr(0, 10),
      vendorId: null,
      customerId: null,
      transaction: false,
      type: "",
    },
    checkbox: false,
    menuStart: false,
    form: {},
    listNature: ["Debit", "Credit"],
    dropDownChartOfAccount: [],
    symbol: "Rp",
  }),

  computed: {
    accountNumberErrors() {
      const errors = [];
      if (!this.$v.form.accountNumber.$dirty) return errors;
      !this.$v.form.accountNumber.required && errors.push("Account Number is required.");
      return errors;
    },
    accountNameErrors() {
      const errors = [];
      if (!this.$v.form.accountName.$dirty) return errors;
      !this.$v.form.accountName.required && errors.push("Account Name is required");
      return errors;
    },
    ...mapState("currency", ["dropDownCurrency"]),
    ...mapState("external", ["vendors", "customers"]),
  },

  methods: {
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        for (let key in Object.keys(this.$v.form)) {
          const input = Object.keys(this.$v.form)[key];
          if (input.includes("$")) return false;
          if (this.$v.form[input].$error) {
            this.$refs[input].focus();
            break;
          }
        }
      } else {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("chartOfAccount/create", this.form)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    clear() {
      this.$v.form.$reset();
      this.form = Object.assign({}, this.defaultForm);
    },
    async getDropDownCurrency() {
      await this.$store.dispatch("currency/getDropDownCurrency");
    },
    async getDropDownChartOfAccount() {
      const response = await this.$store.dispatch("chartOfAccount/getAccounts");
      if (response.status === 200) {
        this.dropDownChartOfAccount = response.data;
      }
    },
    // eslint-disable-next-line no-unused-vars
    filterObject(item, queryText, itemText) {
      return (
        item.accountName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.accountNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    onChangeCurrency(val) {
      let currency = this.dropDownCurrency.find(x => x.value === val);
      let arrays = currency.text.split(" ");
      if (arrays.length === 3) {
        this.symbol = arrays[2].replace("(", "").replace(")", "");
      } else {
        this.symbol = arrays[1].replace("(", "").replace(")", "");
      }
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        this.getDropDownChartOfAccount(),
        this.getDropDownCurrency(),
        await this.$store.dispatch("external/getVendors"),
        await this.$store.dispatch("external/getCustomers"),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },

  mounted() {
    this.form = Object.assign({}, this.defaultForm);
    this.getInitialData();
  },
};
</script>

<style></style>
